export class Utils {
  private static isMobile: boolean;
  private static addMode: boolean = false;
  private static userId: number = 0;
  //private static URL: string = "https://localhost:44301/api/";
  private static URL: string = "https://mapsbackend.azurewebsites.net/api/";

  public static setUserId = (value: number) => {
    Utils.userId = value;
  };

  public static getUserId = (): number => {
    return Utils.userId;
  };

  public static setIsMobile = (value: boolean) => {
    Utils.isMobile = value;
  };

  public static getIsMobile = (): boolean => {
    return Utils.isMobile;
  };

  public static getURL = (): string => {
    return Utils.URL;
  };

  public static setAddMode = (value: boolean) => {
    Utils.addMode = value;
  };

  public static getAddMode = (): boolean => {
    return Utils.addMode;
  };

  public static showToast = (content: string): void => {
    let snackbar = document.getElementById("snackbar");
    if (snackbar != null) {
      snackbar.className = "show";
      console.log(content);
      snackbar.innerHTML = content;
      setTimeout(function () {
        if (snackbar != null) {
          snackbar.className = snackbar.className.replace("show", "");
        }
      }, 2000);
    }
  };
}
