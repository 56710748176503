import * as React from "react";
import { DetailsWindow } from "./DetailsWindow";
// @ts-ignore
import SlidingPane from "react-sliding-pane";
import "../CSS/slide-pane.css";
import { Label, DefaultButton, Icon } from "office-ui-fabric-react";
import Img from "react-image";
import { CircularProgress } from "@material-ui/core";
import { Pin } from "../../assets/models/Pin";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import { Utils } from "../../Utils/Utils";
import { RequestLikePin } from "../../assets/models/RequestLikePin";

interface IProps {
  closeCallback: any;
  marker: Pin;
}

interface IState {
  marker: Pin;
}

export class SlidingPaneCustomSesizari extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { marker: props.marker };
  }

  public render() {
    return (
      <div>
        <SlidingPane
          onAfterOpen={() => {
            let margin = window.innerHeight - 534;
            document
              .getElementsByClassName("slide-pane_from_bottom")[0]
              .setAttribute("style", `margin-top: ${margin}px`);
          }}
          isOpen={true}
          from="bottom"
          width="100%"
          onRequestClose={this.props.closeCallback}
        >
          <div style={{ marginBottom: 20 }}>
            {this.state.marker.currentLike == 1 ? (
              <ThumbUpIcon
                style={{ marginRight: 5 }}
                onClick={() => {
                  this._changeLike(1);
                }}
              />
            ) : (
              <ThumbUpOutlinedIcon
                style={{ marginRight: 5 }}
                onClick={() => {
                  this._changeLike(1);
                }}
              />
            )}
            <span style={{ marginRight: 5 }}>{this.state.marker.likes}</span>
            {this.state.marker.currentLike == -1 ? (
              <ThumbDownIcon
                style={{ marginRight: 5 }}
                onClick={() => {
                  this._changeLike(-1);
                }}
              />
            ) : (
              <ThumbDownOutlinedIcon
                style={{ marginRight: 5 }}
                onClick={() => {
                  this._changeLike(-1);
                }}
              />
            )}
            <Icon
              style={{ float: "right" }}
              iconName="ChromeClose"
              onClick={this.props.closeCallback}
            />
          </div>
          <h3 className={"separated"}>{this.props.marker.subject}</h3>
          <div className={"separated"}>{this.props.marker.description}</div>
          {this.props.marker.url != null && (
            <div>
              <Img
                className="thumb"
                style={{ width: "50%", margin: "0 auto", display: "table" }}
                src={[
                  `data:image/gif;base64,` + this.props.marker.body,
                  require("../../assets/notfound.png"),
                ]}
                loader={<CircularProgress />}
              />
            </div>
          )}
        </SlidingPane>
      </div>
    );
  }

  private _changeLike = (like: number) => {
    if (Utils.getUserId() != 0) {
      let marker = this.state.marker;
      if (like == 1) {
        switch (marker.currentLike) {
          case 1: {
            marker.likes -= 1;
            marker.currentLike = 0;
            break;
          }
          case -1: {
            marker.likes += 2;
            marker.currentLike = 1;
            break;
          }
          case 0: {
            marker.likes += 1;
            marker.currentLike = 1;
            break;
          }
        }
      }
      if (like == -1) {
        switch (marker.currentLike) {
          case 1: {
            marker.likes -= 2;
            marker.currentLike = -1;
            break;
          }
          case -1: {
            marker.likes += 1;
            marker.currentLike = 0;
            break;
          }
          case 0: {
            marker.likes -= 1;
            marker.currentLike = -1;
            break;
          }
        }
      }

      this.setState({ marker });

      let request = new RequestLikePin();
      request.userId = Utils.getUserId();
      request.pinId = marker.id;
      request.value = like;

      fetch(Utils.getURL() + "pins/like", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(request),
      }).then((res) => {
        return res.json().then((body) => {
          console.log(body);
        });
      });
    } else {
      Utils.showToast("Trebuie sa fiți autentificat pentru a vota");
    }
  };
}
