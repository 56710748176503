import * as React from "react";
import { DetailsWindow } from "./DetailsWindow";
// @ts-ignore
import SlidingPane from "react-sliding-pane";
import "../CSS/slide-pane.css";
import { Label, DefaultButton, Icon } from "office-ui-fabric-react";
import Img from "react-image";
import { CircularProgress, TextField, Typography } from "@material-ui/core";
import { Pin } from "../../assets/models/Pin";
import { Utils } from "../../Utils/Utils";
import { pink } from "@material-ui/core/colors";

interface IProps {
  addMarker: any;
}

interface IState {
  isOpen: boolean;
  imageName: string;
}

export class SlidingPaneCustomSesizariAdd extends React.Component<
  IProps,
  IState
> {
  private _inputOpenFileRef: any;

  private _subject: string = "";
  private _description: string = "";
  private _file: any = null;

  private _lat: any;
  private _lng: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpen: false,
      imageName: "",
    };
  }

  public render() {
    return (
      <div>
        <SlidingPane
          onAfterOpen={() => {
            let margin = window.innerHeight - 534;
            document
              .getElementsByClassName("slide-pane_from_bottom")[0]
              .setAttribute("style", `margin-top: ${margin}px`);
          }}
          isOpen={this.state.isOpen}
          from="bottom"
          width="100%"
          onRequestClose={this._onClose}
        >
          <Icon
            style={{ float: "right" }}
            iconName="ChromeClose"
            onClick={this._onClose}
          />
          <div>
            <h3 style={{ marginBottom: 10 }}>Adăugare sesizare</h3>
            <input
              ref={(input: any) => (this._inputOpenFileRef = input)}
              onChange={(evt) => this._changeInput(evt)}
              type="file"
              style={{ display: "none" }}
            />
            <div style={{ width: "100%", margin: 5 }}>
              <TextField
                onChange={this._changeSubject}
                style={{ width: "100%" }}
                label="Subiect"
                variant="outlined"
              />
            </div>
            <div style={{ width: "100%", margin: 5 }}>
              <TextField
                onChange={this._changeDescription}
                style={{ width: "100%" }}
                label="Descriere problema"
                variant="outlined"
                multiline
                rows={3}
              />
            </div>
            <div style={{ margin: 5 }}>
              <DefaultButton
                style={{
                  borderRadius: 5,
                }}
                text={"Adauga imagine"}
                onClick={() => {
                  this._inputOpenFileRef.click();
                }}
              />
              <Typography>{this.state.imageName}</Typography>
            </div>
            <div style={{ margin: 5 }}>
              <DefaultButton
                style={{
                  borderRadius: 5,
                }}
                text={"Adauga pin"}
                onClick={() => {
                  if (this._subject != "" && this._description != "") {
                    let pin = new FormData();
                    pin.append("userId", Utils.getUserId().toString());
                    pin.append("subject", this._subject);
                    pin.append("description", this._description);
                    pin.append("latitude", this._lat);
                    pin.append("longitude", this._lng);

                    if (this._file != null) {
                      pin.append("files", this._file);
                    }

                    console.log("Pin de adaugat", pin);

                    fetch(Utils.getURL() + "pins/add", {
                      method: "POST",
                      body: pin,
                    }).then((res) => {
                      return res.json().then((body) => {
                        if (body == -1) {
                          Utils.showToast("Eroare, incercati din nou");
                        } else {
                          console.log(body);
                          this.props.addMarker(body);
                          this._onClose();
                        }
                      });
                    });
                  } else {
                    Utils.showToast(
                      "Completati subiectul si descrierea pentru a adauga sesizarea"
                    );
                  }
                }}
              />
            </div>
          </div>
        </SlidingPane>
      </div>
    );
  }

  private _changeInput = (evt: any) => {
    if (evt.target.files != null && evt.target.files.length > 0) {
      this._file = evt.target.files[0];
      this.setState({ imageName: this._file.name });
    }
  };

  private _changeSubject = (e: any) => {
    this._subject = e.target.value;
  };

  private _changeDescription = (e: any) => {
    this._description = e.target.value;
  };

  private _onClose = () => {
    this._file = null;
    this._subject = "";
    this._description = "";
    this.setState({ isOpen: false, imageName: "" });
  };
}
