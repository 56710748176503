import * as React from "react";
import { compose, withProps } from "recompose";
import { withGoogleMap, GoogleMap, withScriptjs } from "react-google-maps";
import { Utils } from "../Utils/Utils";
import { Pin } from "../assets/models/Pin";
import { ClusterMarkersComponentSesizari } from "./ClusterMarkersComponentSesizari";
import { SlidingPaneCustomSesizariAdd } from "./InfoWindowComponents/SlidingPaneCustomSesizariAdd";
import { RequestPins } from "../assets/models/RequestPins";

interface IProps {}
interface IState {
  markers: Pin[];
  height: number;
}

let clasa: any;
let _map: any;

export class MapSesizari extends React.Component<IProps, IState> {
  private _addRef: any;

  constructor(props: IProps) {
    super(props);

    let _markers: Pin[] = [];

    clasa = this;

    let pin1 = new Pin();
    pin1.id = 1;
    pin1.subject = "subj1";
    pin1.description = "descr1";
    pin1.latitude = 45.7747313;
    pin1.longitude = 24.147789;
    pin1.url =
      "https://primariaselimbar.ro/wp-content/uploads/53267018_1141792542660204_291248007288979456_o-2-678x381.jpg";
    pin1.likes = 50;
    pin1.currentLike = 1;

    let pin2 = new Pin();
    pin2.id = 2;
    pin2.subject = "subj2";
    pin2.description = "descr2";
    pin2.latitude = 45.800006;
    pin2.longitude = 24.161217;
    pin2.url =
      "https://primariaselimbar.ro/wp-content/uploads/Anunturi-de-interes-public-18-678x381.jpg";
    pin2.likes = 20;
    pin2.currentLike = -1;

    // _markers.push(pin1);
    // _markers.push(pin2);

    let request = new RequestPins();
    request.id = Utils.getUserId();

    fetch(Utils.getURL() + "pins", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        data.map((element: any) => {
          _markers.push(element);
        });

        this.setState({ markers: _markers });
        this.forceUpdate();
      });

    this.state = {
      markers: _markers,
      height: window.innerHeight,
    };
  }

  public componentDidMount() {
    let object = document.getElementById("header");
    let headerHeight: number = 0;

    if (object != null) {
      headerHeight = object.offsetHeight;
    }

    this.setState({ height: window.innerHeight - headerHeight }, () => {
      this.forceUpdate();
    });
  }

  public shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    if (this.state.height != nextState.height) {
      return true;
    }

    return false;
  }

  public render() {
    const GoogleMapExample = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyDWM9_q_-F_3dWiZRhIPRmXH-1lGERjy0M&v=3.exp&libraries=geometry,drawing,places&language=ro&region=RO",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: this.state.height }} />,
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withScriptjs,
      withGoogleMap
    )(() => (
      <GoogleMap
        onClick={(e) => {
          if (Utils.getAddMode()) {
            let lat = e.latLng.lat();
            let lng = e.latLng.lng();
            console.log(lat, lng);

            if (this._addRef != null) {
              this._addRef._lat = lat;
              this._addRef._lng = lng;
              this._addRef._subject = "";
              this._addRef._description = "";
              this._addRef._file = null;
              this._addRef.setState({ isOpen: true });
            }
          }
        }}
        ref={(map) => {
          _map = map;
          var latlngList: any[] = [];
          var bounds = new window.google.maps.LatLngBounds();

          this.state.markers.forEach((marker) => {
            if (marker.latitude != null && marker.longitude != null) {
              latlngList.push(
                new window.google.maps.LatLng(marker.latitude, marker.longitude)
              );
            }
          });

          latlngList.forEach((n) => {
            bounds.extend(n);
          });

          if (map != null) {
            map.fitBounds(bounds);
          }

          console.log("Bounds", bounds);
        }}
        //defaultZoom={this.state.zoom}
        //defaultCenter={{ lat: this.state.lat, lng: this.state.lng }}
      >
        <ClusterMarkersComponentSesizari markers={this.state.markers} />
      </GoogleMap>
    ));

    return (
      <div>
        <SlidingPaneCustomSesizariAdd
          ref={(input: any) => (this._addRef = input)}
          addMarker={this._addMarker}
        />
        <GoogleMapExample />
      </div>
    );
  }

  private _addMarker = (pin: Pin) => {
    let markers = this.state.markers;
    markers.push(pin);
    this.setState({ markers });
    this.forceUpdate();
  };
}

window.addEventListener("resize", function (event) {
  let object = document.getElementById("header");
  let height: number = 0;

  if (object != null) {
    height = object.offsetHeight;
  }

  if (_map != null) {
    clasa.setState({
      height: window.innerHeight - height,
      zoom: _map.getZoom(),
      lat: _map.getCenter().lat(),
      long: _map.getCenter().lng(),
    });
  }
});
