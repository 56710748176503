import * as React from "react";
import { InfoWindow } from "react-google-maps";
import { DetailsWindow } from "./DetailsWindow";
import "../CSS/info-window.css";
import { Pin } from "../../assets/models/Pin";
import Img from "react-image";
import { CircularProgress } from "@material-ui/core";

interface IProps {
  closeCallback: any;
  marker: Pin;
}

interface IState {}

export class InfoWindowCustomSesizari extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hideDialog: true,
    };
  }

  public render() {
    return (
      <div>
        <InfoWindow onCloseClick={this._onWindowClose}>
          <div style={{ overflow: "hidden", margin: 10, minWidth: 600 }}>
            <h3 className={"separated"}>{this.props.marker.subject}</h3>
            <div className={"separated"}>{this.props.marker.description}</div>
            <div>
              <Img
                className="thumb"
                style={{ width: "50%", margin: "0 auto", display: "table" }}
                src={[
                  this.props.marker.url,
                  require("../../assets/notfound.png"),
                ]}
                loader={<CircularProgress />}
              />
            </div>
          </div>
        </InfoWindow>
      </div>
    );
  }

  private _onWindowClose = () => {
    this.props.closeCallback();
  };
}
