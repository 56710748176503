export class Pin {
  id: number = 0;
  userId: number = 0;
  subject: string = "";
  description: string = "";
  latitude: any;
  longitude: any;
  url: string = "";
  likes: number = 0;
  currentLike: number = 0;
  files: any;
  body: any;
}
