import * as React from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { Utils } from "../Utils/Utils";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Label, DefaultButton } from "office-ui-fabric-react";
// @ts-ignore
import Workbook from "react-xlsx-workbook";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import { Card } from "@material-ui/core";
import "./CSS/ListComponent.css";

interface IProps {
  markers: any[];
  filters: any[];
}

interface IState {
  columns: IColumn[];
  items: IListItem[];
}

interface IListItem {
  icon: string;
  title: string;
  properties: any;
}

export class ListComponent extends React.Component<IProps, IState> {
  private _columns: IColumn[] = [];
  private _printData: any[] = [];

  constructor(props: IProps) {
    super(props);

    if (Utils.getIsMobile()) {
      this._columns.push({
        key: "column1",
        name: "Name",
        minWidth: 100,
        maxWidth: window.innerWidth - 20,
        onColumnClick: this._onColumnClick,
        onRender: (item: IListItem) => {
          return (
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <img
                  style={{
                    width: 25,
                    height: 25,
                    marginRight: 10,
                    float: "left",
                  }}
                  src={item.icon}
                />
                <Typography
                  style={{
                    overflow: "hidden",
                    whiteSpace: "normal",
                  }}
                >
                  {item.title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ display: "" }}>
                  {props.filters.map((filter: any) => {
                    return (
                      <>
                        <Label
                          className={"colTitle"}
                          style={{ margin: 0, padding: 0 }}
                        >
                          {filter.name}
                        </Label>
                        <Label
                          className={"colValue"}
                          style={{ margin: 0, padding: 0 }}
                        >
                          {item.properties[filter.property]}
                        </Label>
                      </>
                    );
                  })}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        },
      });

      this.setState({
        columns: this._columns,
      });
    } else {
      this._columns.push({
        key: "column1",
        name: "File Type",
        iconName: "Page",
        minWidth: 16,
        maxWidth: 16,
        onColumnClick: this._onColumnClick,
        onRender: (item: IListItem) => {
          return <img style={{ width: 25, height: 25 }} src={item.icon} />;
        },
      });
      this._columns.push({
        key: "column2",
        name: "Name",
        minWidth: 20,
        maxWidth: 300,
        onColumnClick: this._onColumnClick,
        onRender: (item: IListItem) => {
          return <div className="wordwrap ">{item.title}</div>;
        },
      });

      let i: number = 2;

      if (props.filters != undefined) {
        for (let filter of props.filters) {
          i++;

          this._columns.push({
            key: "column" + i,
            name: filter.name,
            minWidth: 20,
            maxWidth: 200,
            onColumnClick: this._onColumnClick,
            onRender: (item: IListItem) => {
              return (
                <div className="wordwrap ">
                  {item.properties[filter.property]}
                </div>
              );
            },
          });
        }
      }

      this.setState({
        columns: this._columns,
      });
    }

    let _allItems = [];

    for (let i = 0; i < props.markers.length; i++) {
      let properties: any = {};

      let printObj: any = {
        Denumire: props.markers[i].title,
      };

      for (let property of props.markers[i].FilterValues.PinFilterValues) {
        if (property.key == undefined) {
          properties[property.filterProperty] = property.value;
          printObj[property.filterName] = property.value;
        } else {
          properties[property.filterProperty] = property.key;
          printObj[property.filterName] = property.key;
        }
      }

      _allItems.push({
        title: props.markers[i].title,
        icon: props.markers[i].iconURL,
        properties: properties,
      });

      this._printData.push(printObj);
    }

    this.setState({
      items: _allItems,
    });

    this.state = {
      items: _allItems,
      columns: this._columns,
    };
  }

  public componentWillReceiveProps(props: IProps) {
    let _allItems = [];
    for (let i = 0; i < props.markers.length; i++) {
      let properties: any = {};

      for (let property of props.markers[i].FilterValues.PinFilterValues) {
        if (property.key == undefined) {
          properties[property.filterProperty] = property.value;
        } else {
          properties[property.filterProperty] = property.key;
        }
      }

      _allItems.push({
        title: props.markers[i].title,
        icon: props.markers[i].iconURL,
        properties: properties,
      });
    }

    this.setState({
      items: _allItems,
    });
  }

  public render() {
    const { columns, items } = this.state;

    let columnsXLS = null;
    if (this._printData.length > 0) {
      columnsXLS = Object.keys(this._printData[0]).map((key) => {
        return <Workbook.Column label={key} value={key} />;
      });
    }

    if (Utils.getIsMobile()) {
      return (
        <>
          {/* <div
            style={{
              marginTop: 20,
              marginBottom: 5,
              textAlign: Utils.getIsMobile() ? "center" : "left",
            }}
          >
            <Workbook
              filename="Investitii.xlsx"
              element={<DefaultButton text={"Export Excel"} />}
            >
              <Workbook.Sheet data={this._printData} name="Investitii">
                {columnsXLS}
              </Workbook.Sheet>
            </Workbook>

            <PDFDownloadLink
              document={this._generatePDF()}
              fileName="Investitii.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <DefaultButton text={"Export PDF"} />
                )
              }
            </PDFDownloadLink>
          </div> */}

          <DetailsList
            items={items}
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            isHeaderVisible={true}
          />
        </>
      );
    } else {
      return (
        <Card
          style={{
            marginTop: 10,
            padding: 20,
            boxShadow: "2px 2px 2px 2px #888888",
          }}
        >
          <div
            style={{
              marginTop: 20,
              marginBottom: 5,
              textAlign: Utils.getIsMobile() ? "center" : "left",
            }}
          >
            <Workbook
              filename="Investitii.xlsx"
              element={<DefaultButton text={"Export Excel"} />}
            >
              <Workbook.Sheet data={this._printData} name="Investitii">
                {columnsXLS}
              </Workbook.Sheet>
            </Workbook>

            <PDFDownloadLink
              document={this._generatePDF()}
              fileName="Investitii.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <DefaultButton text={"Export PDF"} />
                )
              }
            </PDFDownloadLink>
          </div>

          <DetailsList
            items={items}
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            isHeaderVisible={true}
          />
        </Card>
      );
    }
  }

  private _generatePDF = () => {
    return (
      <Document>
        <Page>
          <Text style={{ fontSize: 18, margin: 20 }}>Lista investitii:</Text>
          {this._printData.map((item: any) => {
            return (
              <Text style={{ fontSize: 12, margin: "10px 20px 10px 20px" }}>
                {Object.keys(item).map((key) => {
                  return key + ": " + item[key] + " / ";
                })}
              </Text>
            );
          })}
        </Page>
      </Document>
    );
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._copyAndSort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _copyAndSort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    return items
      .slice(0)
      .sort((a: T, b: T) =>
        (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
      );
  }
}
